.gradient-section {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  flex-direction: column;
  background: linear-gradient(90deg, #6f25fe, #8117dd, #8c1bc3);
  background-size: 600% 600%;
  min-height: 450px;
}

.topgradient {
  width: 45vw;
  height: calc(100% - 65vh);
  background-color: #c7117f8c;
  position: absolute;
  box-shadow: 0px 0px 10px 5px #3fa4fd50;
  border-radius: 50%;
  filter: blur(15px);
  top: -150px;
  left: -250px;
  z-index: 1;
}

.clipPathResolver {
  width: 100%;
  height: 110px;
  background: bisque;
  clip-path: polygon(0 25%, 100% 0, 100% 100%, 0% 100%);
  position: relative;
  top: -95px;
}

.bisqueClass {
  background-color: bisque;
}

.gradient-line {
  background-image: url(../img/line.png);
  width: 100vw;
  min-height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -65px;
}

.neon-container {
  position: absolute;
  margin-top: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
}

.neon-bg {
  width: 170px;
  height: 245px;
  background-color: #3fa3fd;
  position: absolute;
  box-shadow: 0px 0px 10px 5px #3fa3fd;
  border-radius: 50%;
  filter: blur(15px);
  z-index: 1;
}

.neon-image {
  width: 300px;
  height: auto;
  position: relative;
  z-index: 2;
  top: 50px;
}

.sq4-title {
  font-size: 60px;
  text-align: center;
  word-break: break-word;
  z-index: 12;
}

.sq4-p {
  text-align: center;
  font-size: 20px;
  font-family: sans-serif;
}

.button-video {
  width: 350px;
  height: 100px;
  background-color: #ffffff;
  margin: -95px auto 0;
  z-index: 3;
  position: relative;
  border-radius: 7px;
  border: 8px solid rgba(148, 145, 145, 0.205);
  display: flex;
  transition: all ease-in-out 0.5s;
}

.button-video p {
  margin: auto 10px;
}

.play-btn {
  width: 150px;
  height: 100%;
  background-color: #3fa3fd;
  clip-path: circle(70% at 77% 76%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 28px solid white;
  position: absolute;
  top: 34px;
  right: 8px;
  transform: rotate(270deg);
}

.presentation-section {
  display: flex;
  justify-content: center;
  margin: 30px auto !important;
}

.presentation-section article {
  height: 375px;
  max-width: 500px;
  background-color: #f2d8ff;
  text-align: center;
  padding: 30px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;

  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.presentation-section article h2 {
  margin-top: 15px;
}

.presentation-section article p {
  margin-top: 15px;
  font-family: "Roboto";
  font-weight: bold;
}

.s85 {
  margin: auto;
  width: 85%;
}

.presentation-coach {
  background-color: #000;
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  /* Background color with transparency */
  box-shadow: 0 4px 8px rgba(167, 72, 72, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  /* Applies the blur effect */
  border-radius: 10px;
  padding: 35px 20px;
}

.wrapper-coach {
  display: flex;
  flex-direction: column;
}

.presentation-coach {
  margin: 35px auto;
}

.presentation-coach img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.presentation-coach-txt {
  color: rgb(0, 0, 0);
  margin: 10px;
}

.presentation-coach h2 {
  text-decoration: underline;
}

.presentation-coach p {
  margin: 15px 0;
}

.presentation-coach-img {
  height: 150%;
}

.presentation-coach .img-line {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  z-index: -1;
}

.undefined-section {
  display: flex;
  justify-content: center;
}

.undefined-section article {
  display: flex;
  width: 30%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.undefined-section article .img-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: white;
}

.undefined-section article .img-container img {
  position: relative;
  top: 50px;
  right: 50px;
}

.undefined-section article h2 {
  font-size: 15px;
  margin-top: 30px;
}

.undefined-section article p {
  font-family: "Roboto";
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.st4-present {
  text-align: center;
  margin: 30px 0;
}

.pre-qualities {
  display: flex;
  justify-content: space-between;
}

.pre-qualities h2 {
  font-size: 50px;
  width: 50%;
}

.pre-qualities img {
  transform: rotate(40deg);
}

.qualities {
  display: flex;
  margin: 20px 0 0;
  flex-wrap: wrap;
}

.qualities article {
  border-radius: 7px;
  border: 2px solid #c7abab;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 10px 0;
  padding: 3px;
  transition: all 0.3s ease-in-out;
  height: 50px;
}

.qualities article:hover {
  color: white;
  background-color: #000;
}

.qualities article:nth-child(1) {
  margin: 0 10px 0 0;
}

.qualities article h3 {
  font-family: sans-serif;
  font-size: 15px;
  padding: 10px;
}

.app-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-section p {
  font-size: 13px;
}

.app-section h2 {
  margin: 10px 0;
  font-size: 20px;
}

.under-p-title {
  font-family: sans-serif;
  font-size: 15px !important;
  font-weight: bold;
}

.app-section img {
  width: clamp(15px, 40vw, 400px);
  border-radius: 7%;
}

.button-dw {
  width: 300px;
  height: 100px;
  background-color: #ffffff;
  margin: 20px 0 0 0;
  position: relative;
  border-radius: 7px;
  border: 8px solid rgba(255, 0, 0, 0.205);
  display: flex;
  transition: all ease-in-out 0.5s;
  align-items: center;
}

.button-dw p {
  margin: auto 10px;
}

.phone-logo {
  position: relative;
  left: 10px;
}

.bg-removing {
  mix-blend-mode: darken;
}

@media screen and (max-width: 1000px) {
  .gradient-line {
    margin-top: -180px;
  }
  .sq4-title {
    font-size: 6vw;
  }

  .sq4-p {
    font-size: 2.8vw;
  }

  .neon-container {
    margin-top: 300px;
  }

  .pre-qualities {
    align-items: center;
  }

  .pre-qualities h2 {
    font-size: 25px;
  }

  .topgradient {
    left: -100px;
  }
}

@media screen and (max-width: 600px) {
  .sq4-title {
    font-size: 8vw;
  }

  .topgradient {
    left: -50px;
  }
}

@media screen and (max-width: 768px) {
  .undefined-section {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    justify-items: center;
  }

  .li755 {
    flex-direction: column;
  }

  .li755 li {
    min-height: 100px !important;
    text-align: left !important;
    margin: 10px 0 !important;
  }

  .undefined-section article {
    margin: 10px 0;
    width: unset;
  }

  .presentation-coach p {
    font-size: 2vw;
  }

  .presentation-coach-img img {
    width: 150px;
    height: 150px;
  }

  .app-section {
    flex-direction: column;
    align-items: normal;
  }

  .mockup-div {
    display: flex;
    justify-content: center;
  }

  .mockup-div img {
    position: relative;
    bottom: 50px;
  }

  .app-section img {
    width: 320px;
  }

  .button-dw {
    margin: 30px auto 0;
  }
}

@media screen and (max-width: 600px) {
  .wrapper-coach {
    flex-direction: column;
  }

  .img-line {
    display: none;
  }

  .presentation-coach p {
    font-size: 3vw;
  }
}

@media screen and (max-width: 500px) {
  .button-video {
    width: 280px;
  }
  .button-video p {
    font-size: 14px;
  }

  .undefined-section {
    grid-template-columns: repeat(1, 3fr);
  }

  .presentation-coach {
    width: 95% !important;
  }

  .pre-qualities img {
    width: 60px;
    height: 60px;
  }

  .button-dw {
    width: 250px;
    margin: 10px auto;
  }

  .phone-logo {
    left: 5px;
  }
}

.box-video {
  width: 100%;
}

#header-vid {
  object-fit: cover;
  width: 100%;
  height: 89vh;
  max-height: 89vh;
}

@supports (-webkit-touch-callout: none) {
  .allevenphone {
    display: none;
  }

  .text-box {
    color: red;
  }
}

.text-box {
  color: rgb(255, 255, 255);
  position: absolute;
  bottom: 10%;
  display: flex;
  flex-direction: column;
}

.text-box h1 {
  font-size: 7vw;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}

.text-box p {
  font-size: 2.1vw;
  font-weight: 500;
}

.buttontext {
  border: 2px solid white;
  color: white;
  transition: all 0.5s ease-in-out;
}

.buttontext:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 700px) {
  .text-box h1 {
    font-size: 10vw;
  }

  .text-box p {
    font-size: 3vw;
  }

  .text-box {
    align-items: center;
    width: 100%;
  }
}

@media screen and (min-width: 701px) {
  .text-box {
    left: 25px;
  }

  .buttontext {
    width: 33.1vw;
    font-size: 2vw;
  }

  .buttontext:hover {
    width: 33vw;
  }
}

@media screen and (min-width: 701px) and (max-width: 1100px) {
  .text-box {
    font-size: 9vw;
  }
}

.philo {
  margin-bottom: 30px;
  margin-top: 20px;
}

.voirplus {
  display: none;
  cursor: pointer;
}

.vplus {
  height: inherit !important;
}

.wrapper-comment {
  position: relative;
  display: flex;
  align-items: center;
  padding: 25px 0;
  background-color: white;
}

.items {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  height: 330px;
}

.items.active {
  cursor: grab;
}

.item {
  display: inline-block;
  margin-left: 15px;
  user-select: none;
  width: 50%;
  min-width: 225px;
  color: #000;
  font-weight: bold;
}

.item p {
  margin: 15px 10px;
  font-size: 15px;
  white-space: pre-line;
}

.item:last-child {
  margin-right: 20px;
}

@media screen and (max-width: 400px) {
  .slide-text {
    font-size: 0.7em;
  }

  .item {
    min-width: 135px;
  }
}

@media screen and (min-width: 500px) {
  .item {
    width: 33%;
  }

  .slide-text {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 500px) {
  .arrowMoving {
    display: none !important;
  }

  .item {
    width: 90%;
    display: block;
    height: unset;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .items {
    flex-direction: column;
  }

  .wrapper-comment {
    flex-direction: column;
  }

  .voirplus {
    display: block;
    border: 1px solid #000;
    margin-bottom: 20px;
  }

  .slide-text {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 800px) {
  .item {
    width: 25%;
  }

  .slide-text {
    font-size: 2em;
  }
}

@media screen and (min-width: 1200px) {
  .wrapper-comment {
    margin-left: -20px;
    padding: 25px 100px;
  }

  .item {
    width: 20%;
  }

  .slide-text {
    font-size: 2.5em;
    margin-left: 30px !important;
  }
}

@media screen and (max-width: 1200px) {
  .item p {
    font-size: 12px;
  }
}

legend {
  font-size: 15px;
  margin-left: 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 100;
}

.slide-text {
  text-transform: uppercase;
  white-space: nowrap;
  margin-left: 10px;
}

hr {
  border: 1px solid #000;
}

.star-rate {
  display: flex;
  justify-content: center;
}

.star-rate img {
  width: 20px;
  margin-top: 10px;
}

@media (min-width: 950px) {
  .slider-- {
    min-width: 30%;
    min-height: 200px;
    margin: 0 20px;
  }

  .sliderarticle {
    display: inline-flex;
    margin-bottom: 20px;
    justify-content: center;
  }

  .slider-- p {
    margin: 5px 15px 0 15px !important;
  }
}

@media (max-width: 950px) {
  .slider-- {
    min-height: 80px;
    margin-top: 30px !important;
  }
}

.slider-- {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  justify-content: center;
}

.slider-- h3 {
  text-align: center;
}

.slider-- p {
  margin: 5px 15px;
  font-weight: 500;
  text-align: center;
}

.gallery-container {
  width: 85%;
  margin: 2rem auto;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 5vw);
  grid-gap: 1.5rem;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
}

.gallery__item--2 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 6;
}

.gallery__item--3 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 6;
}

.gallery__item--4 {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 6;
  grid-row-end: 10;
}

.gallery__item--5 {
  grid-column-start: 9;
  grid-column-end: 6;
  grid-row-start: 6;
  grid-row-end: 10;
}

.textingallery {
  background-color: white;
  color: black;
  text-align: center;
}

.textingallery h2 {
  font-size: 16px;
  margin-top: 20px;
}

@media screen and (min-width: 320px) {
  .textingallery h2 {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  .textingallery h2 {
    font-size: 47px;
  }
}

.h1offer {
  color: white;
  font-size: 30px;
  border-bottom: 2px solid rgb(255, 255, 255);
  line-height: 0.1em;
}

.h1offer span {
  background-color: bisque;
  color: black;
  padding: 0 10px;
}

.philo2za {
  display: flex;
  width: 100%;
  margin: 30px 0;
}

.philo2z1 {
  width: 100%;
}

.philo2z1 h2 {
  font-size: 25px;
  margin: 30px 115px 0 20px;
}

@media (min-width: 950px) {
  .philo2z1 {
    min-height: 400px;
  }
}

@media screen and (max-width: 950px) {
  .philo2za {
    flex-direction: column;
  }

  .philo2z1 {
    width: 100%;
  }

  .philo2text {
    width: 100%;
  }
}

.philo2button {
  border: none;
  margin: 10px 20px;
  width: 100px;
  height: 30px;
  background-color: black;
  color: white;
  transition: all 0.5s ease;
  font-size: 15px;
  padding: 5px 0 0 8px;
}

.philo2button:hover {
  cursor: pointer;
  width: 105px;
  height: 35px;
  padding: 7px 0 0 11px;
}

.philo2img {
  justify-content: center;
  display: grid;
}

@media (min-width: 650px) and (max-width: 950px) {
  .philo2z1 {
    display: flex;
  }
}

.philo2img2 {
  width: 200px;
  height: 200px;
}

@media (max-width: 812px) {
  .philo2img2 {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 650px) {
  .philo2z1 h2 {
    font-size: 21px;
  }
}

.colwhite {
  color: rgb(255, 255, 255);
}

.bacblack {
  background-color: black;
}

.colblack {
  color: rgb(0, 0, 0);
}

.bacwhite {
  background-color: white;
  color: black;
}

.slider {
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: white;
  padding-top: 20px;
}

.slider::before,
.slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slider .slide {
  margin: 20px 20px;
  height: 135px;
  min-width: 300px;
  padding: 0 100px;
}

.slider p {
  display: grid;
  text-align: center;
  margin-top: 10px;
}

.ytifrore {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.ytiframe {
  width: 80vw;
  height: 50vh;
  background-color: rgb(255, 255, 255);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 999;
}

@media screen and (min-width: 1000px) {
  .ytiframe {
    width: 65vw;
    height: 30vw;
  }
}

.closep {
  font-size: 20px;
  display: grid;
  width: 100%;
  justify-content: center;
  background-color: grey;
  cursor: pointer;
}

.closepApp {
  font-size: 20px;
  display: grid;
  width: 100%;
  justify-content: center;
  background-color: grey;
  cursor: pointer;
}

.ytifr {
  margin-top: 0;
  height: 100%;
  width: 100%;
}

.displaymodal {
  display: none;
}

.watch-more {
  cursor: pointer;
}

.textModalApp {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.textModalApp li {
  text-align: center;
}

.animate-png {
  animation: rotateInfinite 4s linear infinite;
}

@keyframes rotateInfinite {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.app-section img {
  height: unset;
  bottom: 0;
}

.avantages-wrapper  {
  margin: 20px 0 30px 0;
}

.avantages-wrapper img {
  width: 50px;
  height: 50px;
}
.avantages-wrapper div {
  height: min-content;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider-infinite {
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  margin: auto;
  overflow: hidden;
  position: relative;
  margin: 10px 0;
}
.slider-infinite::before,
.slider-infinite::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider-infinite::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider-infinite::before {
  left: 0;
  top: 0;
}
.slider-infinite .slide-track {
  animation: scroll 35s linear infinite;
  display: flex;
  width: calc(250px * 11);
}
.slider-infinite .slide {
  padding: 0 5px;
  margin: 10px 0;
}

.slider-infinite .slide img {
  mix-blend-mode: color-burn;
}

.wrapper-presentation-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wrapper-presentation-title article {
  background-color: #341e5a;
  padding: 20px 15px;
  border-radius: 20px 20px 28px 11px;
  font-size: 15px;
  margin: 3px;
}

.wrapper-presentation-title .selected {
  background-color: #7223FA;
}

.wrapper-presentation-title article p {
  font-family: "Roboto", sans-serif;
  color: white;
}

.presentation-slider {
  background-color: #000;
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 8px rgba(167, 72, 72, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.presentation-slider .img-line {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30%;
  width: 100%;
  z-index: -1;
}

.presentation-texte {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 35px;
  flex-direction: column;
}

.presentation-texte img {
  width: 80%;
  height: auto;
  object-fit: contain;
}

.presentation-texte p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  width: 100%;
  margin-top: 20px;
}

@media screen and (max-width: 1000px) {

  .presentation-texte img {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
}