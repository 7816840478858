.blog--post {
  margin: 80px auto;
  width: 70%;
}

.blog--post-h1 {
  color: rgb(0, 0, 0);
  font-size: 42px;
}

.blog--post-p {
  color: rgb(0, 0, 0);
}

.articlepost {
  color: rgb(0, 0, 0);
  font-size: 19px;
  line-height: 40px;
  margin-top: 60px;
}

.blog--img {
  width: 100%;
  height: 100%;
  margin: 35px 0;
}

@media screen and (max-width: 530px) {
  .blog--post {
    width: 85%;
  }

  .articlepost {
    font-size: 19px;
    line-height: 30px;
    margin-top: 45px;
  }
}

@media screen and (min-width: 1200px) {
  .blog--post {
    width: 60%;
  }
}

@media (max-width: 631px) {
  .off2button {
    bottom: -6.5px;
    right: 18px;
  }
}

@media (max-width: 440px) {
  .h1offer {
    font-size: 17px;
  }
  .off2button {
    right: 20px;
  }
}
