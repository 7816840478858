.offres-bloc {
  display: flex;
  justify-content: center;
}

.off-bloc {
  min-width: 18.5%;
  height: 200px;
  margin: 50px 10px 0;
  position: relative;
  left: 30px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 6px 20px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
}

.psem {
  margin-top: 40px;
}

.off-bloc h2 {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
  margin-top: 25px;
}

.off-bloc h2 span {
  background: #ffe9d0;
  padding: 0 10px;
  font-size: 18px;
}

.seance {
  text-align: center;
}

.price-container {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100px;
  margin-top: 7px;
}

.price-container p {
  color: white;
  text-align: center;
  font-size: 40px;
  padding: 20px;
}

.w95 {
  width: 95% !important;
}

.downseance {
  text-align: center;
}

.dmtnav {
  margin: 50px 0 95px 0;
}

@media (min-width: 1150px) {
  .offres-bloc,
  .offres-bloc-personalized {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }

  .off-bloc {
    margin: 80px 10px 0;
    left: 12px;
  }
}

@media (max-width: 1150px) {
  .offres-bloc {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }

  .off-bloc {
    margin: 80px 10px 0;
    left: 12px;
  }
}

@media (max-width: 530px) {
  .offres-bloc {
    display: flex;
    flex-direction: column;
  }

  .off-bloc {
    left: 10px;
  }
}

.offerpay {
  border: solid 1px #000;
  color: black;
  width: 150px;
  height: 40px;
  display: grid;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  transition: 0.5s all ease-in-out;
}

.offerpay:hover {
  background-color: rgb(0, 0, 0);
  color: white;
}

.offerdiv {
  display: flex;
  justify-content: center;
}

.nomt0 {
  margin-top: 25px;
}

.single8 {
  min-width: 25%;
}

#linenone {
  border-bottom: initial;
  line-height: 0;
}

#linenone2 {
  border-bottom: initial;
  line-height: 0;
}

@media (max-width: 653px) {
  #linenone {
    font-size: 19px;
  }

  #linenone2 {
    font-size: 19px;
  }
}
