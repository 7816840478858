.marquee-container {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-weight: 700;
  font-family: "Karla", sans-serif;
  text-transform: uppercase;
  z-index: 999;
}

.marquee {
  position: absolute;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: 0.25rem;
  overflow: hidden;
  padding: 15px 0;
  min-width: 100%;
  user-select: none;
  background-color: #151818;
  transition:
    background-color 300ms cubic-bezier(0.33, 1, 0.68, 1),
    box-shadow 300ms cubic-bezier(0.33, 1, 0.68, 1);
}

.marquee--top {
  top: -1px;
  color: #fedd00;
}

.marquee--left {
  left: -1px;
  bottom: -6vmin;
  transform: rotate(-0.25turn);
  transform-origin: 0 0;
}

.marquee--right {
  left: calc(100% + 1px);
  top: 0;
  transform: rotate(0.25turn);
  transform-origin: 0 0;
}

.marquee--bottom {
  bottom: -1px;
  right: 0;
  transform: rotate(0.5turn);
  color: #fedd00;
}

.marquee div {
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.33, 1, 0.68, 1);
}

.marquee div.active {
  opacity: 0;
}

.page-content h1 {
  font-size: min(max(40px, 6vw), 70px);
  font-weight: 700;
  line-height: 1.2;
}

.li755 {
  display: flex;
}

.li755 li {
  font-family: "Roboto", sans-serif;
  font-size: clamp(15px, 1.4vw, 25px);
  text-align: center;
  margin: 0 5px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.afhh2 {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  margin-top: 10px;
}

.b2px {
  margin-top: 20px;
}

.afhjj {
  margin-top: 10px;
  text-align: center;
}

.avantage {
  display: flex;
  width: 60%;
  margin: auto;
  margin-top: 20px;
}

.avantage div {
  display: flex;
  align-items: center;
}

.avantage article {
  width: 50%;
  margin-right: 10px;
}

.avantage div img {
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (max-width: 580px) {
  .li755 li {
    font-size: 13px;
  }

  .page-content__section {
    width: 70%;
  }

  .avantage {
    flex-direction: column;
  }

  .avantage article {
    width: 100%;
  }
}

.afh1e {
  color: rgb(0, 0, 0);
  text-align: center;
  padding-top: 80px;
  font-size: clamp(16px, 10vw, 50px);
}

@media (min-width: 1230px) {
  .enter44 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }

  .imgenter {
    margin: 30px 20px;
  }
}

@media (max-width: 1230px) {
  .enter44 {
    display: grid;
    grid-template-columns: 0fr 0fr;
  }

  .imgenter {
    width: 210px;
    margin: 30px 20px;
  }

  .imgenter h2 {
    font-size: 16px;
  }
}

@media (max-width: 570px) {
  .enter44 {
    display: grid;
    grid-template-columns: unset;
  }

  .imgenter {
    width: 300px !important;
    margin: 30px 20px;
    width: unset;
  }

  .imgenter img {
    width: 230px;
  }

  .imgenter h2 {
    font-size: 16px;
    color: rgb(255, 255, 255);
  }
}

.imgenter {
  margin: 0 20px;
  background-color: rgb(255, 255, 255);
  width: 350px;
  height: 147px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.imgenter img {
  width: 230px;
  margin-top: 30px;
  background-color: rgb(255, 255, 255);
}

.imgenter h2 {
  text-align: center;
  color: rgb(0, 0, 0);
  margin: 10px 0;
}

@keyframes activate {
  25% {
    color: #ecf232;
    transform: translateY(-12px);
  }

  40% {
    transform: translateY(2px);
  }

  50% {
    transform: translateY(-4px);
  }

  60% {
    color: whitesmoke;
    transform: translateY(1px);
  }

  65%,
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.enter44 {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.avantages-wrapper {
  display: flex;
  justify-content: center;
}

.avantages-bloc {
  background: rgba(255, 255, 255, 0.2);
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 6px 20px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 10px;
  max-width: 500px;
  margin: 0 10px;
}

.morphtext {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.avantages-bloc div p {
  margin-left: 5px;
}

.avantages-bloc div {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

@media screen and (max-width: 600px) {
  .imgenter {
    width: 100% !important;
    margin: 45px 0px !important;
  }

  .imgenter img {
    width: 200px;
  }

  .page-content h1 {
    font-size: 7vw;
  }

  .enter44 {
    grid-template-columns: none !important;
  }
}

@media screen and (max-width: 768px) {
  .avantages-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .avantages-bloc {
    margin: 10px 0;
  }
}

.unfonted {
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}
