.afheader {
  margin: 0px auto 0 auto;
  padding: 25px 0 0 0;
  width: 70%;
}

.inafheader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.inafheadertxt {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.inafheadertxt h1 {
  font-size: 50px;
  color: white;
  text-decoration: underline;
  text-decoration-color: #fedd00;
}

.inafheadertxt p {
  color: #fedd00;
  font-size: 25px;
  font-weight: 700;
  margin: 10px 50px 10px 0;
}

@media screen and (max-width: 1100px) {
  .afheader {
    width: 85%;
  }
}

@media screen and (max-width: 768px) {
  .inafheader {
    flex-direction: column;
    align-items: center;
  }

  .inafheadertxt {
    width: 100%;
    text-align: center;
  }

  .meditation {
    margin-top: 50px;
  }

  .inafheadertxt p {
    font-size: 20px;
    margin: 10px 0 0 0;
  }
}

@media screen and (max-width: 500px) {
  .inafheadertxt p {
    font-size: 16px;
  }

  .inafheadertxt h1 {
    font-size: 22px;
  }

  .meditation {
    width: 190px !important;
    height: auto !important;
  }
}

@media screen and (min-width: 1545px) {
  .meditation {
    width: 570px !important;
    height: 604px !important;
  }

  .inafheadertxt h1 {
    font-size: 85px;
  }

  .inafheadertxt p {
    font-size: 30px;
  }
}

.meditation {
  width: 285px;
  height: 302px;
  transform: rotate(345deg);
}

.bluwa {
  background-color: bisque;
}

.blocmediz {
  width: 85%;
  height: 200px;
  background-image: url("/public/img/medibackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px auto auto;
  padding: 20px;
  border-radius: 10px;
}

.blocmediz div {
  display: flex;
  height: 100%;
  align-items: center;
}

.blocmediz h2 {
  color: white;
  font-size: clamp(16px, 2vw, 25px);
  text-align: center;
}

@media screen and (max-width: 500px) {
  .blocmediz h2 {
    font-size: 15px;
  }

  .blocStep {
    max-width: 100% !important;
  }

  .blocStep .step {
    font-size: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 850px) {
  .blocmediz h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .bloctree {
    flex-direction: column;
  }
  .bloctree article {
    margin: 10px 0 !important;
    width: 100% !important;
  }
}

.bloc2w {
  width: 85%;
  margin: 30px auto;
}

.bloc2w article {
  width: 100%;
  min-height: 250px;
  font-size: 22px;
  background-image: url("/public/img/medibackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.bloc2back3 {
  letter-spacing: 0.5px;
}

.bloc2w article:nth-last-child(1) {
  min-height: 150px;
}

.bloc2w article:nth-last-child(2) {
  padding-top: 20px;
  margin-bottom: 20px;
}

.bloc2w article h2 {
  color: white;
  text-align: center;
  font-size: 20px;
}

@media screen and (max-width: 950px) {
  .bloc2w {
    flex-direction: column;
  }

  .bloc2w article {
    width: 100%;
    margin-bottom: 20px;
  }

  .bloc2w article h2 {
    font-size: 15px;
  }

  .attachedfooter h3 {
    font-size: 15px !important;
  }
}

.quotes {
  border: 1px solid #fff;
  width: 50%;
  margin: auto;
}

.photocont9 {
  width: 70%;
  margin: 30px auto auto;
  background-image: url("/public/img/medibackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 500px;
  justify-content: space-evenly;
}

.photocont9 h2 {
  color: white;
  font-size: 30px;
  margin: 10px 20px 0;
}

.typetaper:before {
  content: "";
  display: block;
  width: 5px;
  height: 500px;
  background-color: white;
  position: absolute;
  margin-left: 42px;
}

.typepar li:before {
  content: "";
  display: block;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: white;
  margin-left: 20px;
  margin-right: 10px;
}

.typepar li {
  white-space: nowrap;
  color: white;
  display: flex;
  align-items: center;
  margin-top: 50px;
}

@media screen and (max-width: 980px) {
  .photocont9 {
    flex-direction: column;
  }

  .typetaper:before {
    height: 417.5px;
  }
}

@media screen and (max-width: 520px) {
  .photocont9 h2 {
    font-size: 22px;
  }

  .typetaper:before {
    height: 347px;
    margin-left: 20px;
  }

  .typepar li:before {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  .typepar li {
    font-size: 13px;
  }

  .photocont9 {
    width: 85%;
  }
}

.beforedefp {
  width: 85%;
  margin: 20px auto;
  height: 295px;
  background-image: url("/public/img/medibackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

.beforedefp h2 {
  color: white;
  text-align: center;
  font-size: 25px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 950px) {
  .beforedefp h2 {
    font-size: 20px;
  }

  .beforedefp {
    height: unset;
    min-height: 250px;
  }
}

.bacpeods {
  background-color: #1e1e1e;
  margin-top: 45px;
  padding-top: 20px;
}

.attachedfooter {
  width: 85%;
  margin: auto;
  height: 100px;
  background-color: white;
  position: relative;
  z-index: 3;
}

.attachedfooter h3 {
  font-size: 24px;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  padding: 3rem 0;
}

.wavemedi {
  height: 150px;
  width: 100%;
}

.meditrain-bg {
  background: linear-gradient(90deg, #6f25fe, #8117dd, #8c1bc3);
  background-size: 600% 600%;
}

.bloctree {
  max-width: 85%;
  margin: 50px auto;
  display: flex;
  justify-content: center;
}

.bloctree article {
  background: rgba(255, 255, 255, 0.2);
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 6px 20px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  width: calc(100% / 3);
  margin: 0 10px;
  padding: 20px;
}

.bloctree article h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.blocText article {
  width: 100%;
  padding: 35px;
  text-align: center;
}

.blocStep {
  width: 100%;
  padding: 15px;
  text-align: left;
  background: rgba(255, 255, 255, 0.2);
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 6px 20px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
}

.journey {
  display: flex;
  gap: 20px;
}

.point-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line {
  width: 2px;
  height: 50px;
  background-color: #007bff;
  margin: 0 auto;
}

.point {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007bff;
}

.step {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.endmeditrain {
  margin-bottom: 15px;
}

.blocStep h2 {
  margin: 0 15px 0 0;
}

.justify-text h2 {
  text-align: justify !important;
}
