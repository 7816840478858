header {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 100px;
  width: 100%;
  color: #fefefe;
  padding: 10px;
  background: linear-gradient(90deg, #6f25fe, #8117dd, #8c1bc3);
  background-size: 600% 600%;
}

@media screen and (max-width: 799px) {
  header {
    flex-direction: unset;
  }

  header .header-logo {
    text-align: center;
    margin: 0 15px;
    z-index: 2;
    position: fixed;
    right: 0px;
    z-index: 9999;
  }
}

header .header-logo {
  text-align: center;
  margin: 0 15px;
  z-index: 2;
}
nav {
  position: relative;
}

nav input[name="toggle-nav"],
nav label[for="toggle-nav"] {
  position: absolute;
  top: -20px;
  width: 35px;
  height: 35px;
  left: 15px;
}

nav input[name="toggle-nav"],
nav label[for="toggle-nav"] {
  position: fixed; /* Utilisez "fixed" au lieu de "absolute" */
  top: 30px; /* Vous pouvez ajuster la valeur en fonction de votre design */
  width: 35px;
  height: 35px;
  left: 20px;
  z-index: 1091;
}

nav input[name="toggle-nav"] {
  display: block;
  cursor: pointer;
  opacity: 0;
  z-index: 1091;
}

nav
  input[name="toggle-nav"]:active:checked
  + label[for="toggle-nav"]
  .menu-icon::after,
nav
  input[name="toggle-nav"]:active:checked
  + label[for="toggle-nav"]
  .menu-icon::before,
nav
  input[name="toggle-nav"]:focus:checked
  + label[for="toggle-nav"]
  .menu-icon::after,
nav
  input[name="toggle-nav"]:focus:checked
  + label[for="toggle-nav"]
  .menu-icon::before,
nav
  input[name="toggle-nav"]:hover:checked
  + label[for="toggle-nav"]
  .menu-icon::after,
nav
  input[name="toggle-nav"]:hover:checked
  + label[for="toggle-nav"]
  .menu-icon::before {
  background-color: #fff;
}

nav label[for="toggle-nav"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1090;
}

nav label[for="toggle-nav"] .menu-icon,
nav label[for="toggle-nav"] .menu-icon::after,
nav label[for="toggle-nav"] .menu-icon::before {
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background-color: #000;
  transition: all 0.5s ease;
}

nav label[for="toggle-nav"] .menu-icon::after,
nav label[for="toggle-nav"] .menu-icon::before {
  position: absolute;
  content: "";
}

nav label[for="toggle-nav"] .menu-icon::before {
  top: 7px;
}

nav label[for="toggle-nav"] .menu-icon::after {
  bottom: 7px;
}

nav input[name="toggle-nav"]:checked ~ .side-nav-panel {
  left: 0;
}

nav input[name="toggle-nav"]:checked + label[for="toggle-nav"] .menu-icon,
nav
  input[name="toggle-nav"]:checked
  + label[for="toggle-nav"]
  .menu-icon::after,
nav
  input[name="toggle-nav"]:checked
  + label[for="toggle-nav"]
  .menu-icon::before {
  background-color: transparent;
}

nav
  input[name="toggle-nav"]:checked
  + label[for="toggle-nav"]
  .menu-icon::after,
nav
  input[name="toggle-nav"]:checked
  + label[for="toggle-nav"]
  .menu-icon::before {
  background-color: #fcfcfc;
}

nav
  input[name="toggle-nav"]:checked
  + label[for="toggle-nav"]
  .menu-icon::before {
  top: 16px;
  transform: rotate(45deg);
}

nav
  input[name="toggle-nav"]:checked
  + label[for="toggle-nav"]
  .menu-icon::after {
  bottom: 16px;
  transform: rotate(-45deg);
}

nav .side-nav-panel {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -100vw;
  width: 90vw;
  max-width: 380px;
  height: 100vh;
  padding-top: 85px;
  background-color: #1e1e1e;
  box-shadow:
    1px 0 5px rgba(22, 24, 26, 0.5),
    1px 0 3px rgba(7, 8, 10, 0.7);
  transition: 0.5s left;
  z-index: 999;
}

nav .top-nav {
  overflow-y: auto;
  z-index: 2;
  margin: 0 15px;
}

nav .top-nav a:link,
nav .top-nav a:visited {
  color: #fcfcfc;
  text-decoration: none;
  font-size: 15px;
}

nav .top-nav .active a:link,
nav .top-nav .active a:visited {
  color: #3c3c3c;
}

nav .top-nav a:active,
nav .top-nav a:focus,
nav .top-nav a:hover {
  text-decoration: underline;
}

nav .top-nav > li {
  min-height: 50px;
  max-height: 100vh;
  width: 95%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  margin-left: 5px;
}

nav .top-nav > li a {
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 2em;
}

nav .top-nav > li > a {
  padding-top: 15px;
}

nav .parent-item {
  position: relative;
}

nav .parent-item > a::after {
  content: "^";
  display: inline-block;
  position: relative;
  top: 2px;
  left: 4px;
  font-size: 1em;
  transform: rotate(90deg);
  transition: all 0.3s;
}

nav .parent-item.active > a::after {
  top: -2px;
  transform: rotate(180deg);
}

nav .submenu {
  background-color: #e8e8e8;
  position: relative;
  top: 12px;
  left: 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  border-bottom-right-radius: 10px;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out,
    max-height 0.2s ease-in-out,
    margin-bottom 0.3s ease-in-out;
}

nav .submenu.active {
  opacity: 1;
  visibility: visible;
  max-height: 100vh;
  margin-bottom: 8px;
  transition: max-height 1s;
}

nav .submenu li {
  position: relative;
  padding-left: 1em;
  background-color: #fcfcfc;
}

nav .submenu li::before {
  content: "▸";
  display: block;
  position: absolute;
  top: 10px;
  left: 32px;
  color: #000;
}

nav .submenu li a {
  padding-top: 12px;
  padding-bottom: 12px;
}

@media screen and (min-width: 800px) {
  nav label[for="toggle-nav"] {
    display: none;
  }

  nav input[name="toggle-nav"] {
    display: none;
  }

  nav .top-nav > li {
    min-height: unset;
    max-height: unset;
    width: unset;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    letter-spacing: 1px;
    cursor: pointer;
    color: black;
    z-index: 1095;
    display: inline-block;
    margin-left: 5px;
  }

  nav .side-nav-panel {
    display: flex;
    justify-content: center;
    position: unset;
    top: 0;
    left: unset;
    width: unset;
    max-width: unset;
    height: 100px;
    padding-top: unset;
    background-color: unset;
    box-shadow: none;
    transition: none;
    margin-left: 20px;
    text-align: right;
  }

  nav .top-nav a:link,
  nav .top-nav a:visited {
    color: #fff;
  }

  nav .top-nav > li a {
    padding-left: 5px;
    padding-top: unset;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px) {
  header {
    padding: 5px;
  }
}
