.carousel {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 400px;
  -webkit-perspective: 500px;
  perspective: 500px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.carousel .carousel-item {
  background-color: white;
  visibility: hidden;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -90px;
}

.carousel .carousel-item > img {
  width: 100%;
}

@-webkit-keyframes pulse-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

.carousel {
  height: 700px;
  perspective: 600px;
}

.image {
  width: 260px;
  height: 260px;
  background-repeat: no-repeat;
  background-size: cover;
}

.image1 {
  background-image: url("/public/img/squared/flashlsq.webp");
}

.image2 {
  background-image: url("/public/img/squared/lsqpoids.webp");
}

.image3 {
  background-image: url("/public/img/squared/bike2lsq.webp");
}

.image4 {
  background-image: url("/public/img/squared/yogalsq.webp");
}

.image5 {
  background-image: url("/public/img/squared/lsqelast.webp");
}

.image6 {
  background-image: url("/public/img/squared/lsqcentrale.webp");
}

.image7 {
  background-image: url("/public/img/squared/yogalsq.webp");
}

.carousel-item h2 {
  width: 260px;
}

.carousel-item p {
  width: 260px;
}

@media screen and (max-width: 950px) {
  .carousel .carousel-item h2 {
    font-size: 15px !important;
  }
}

@media screen and (min-width: 550px) {
  .carousel {
    height: 800px;
  }

  .image {
    width: 400px;
    height: 400px;
  }

  .carousel-item {
    width: 400px !important;
    height: 400px !important;
  }

  .carousel-item h2 {
    width: 400px;
  }

  .carousel-item p {
    width: 400px;
  }
}

.carousel .carousel-item h2 {
  margin: -5px 0 0;
  padding: 0;
  background-color: #fff;
  color: #000;
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
}

.carousel .carousel-item p {
  margin: 0px 0 0;
  padding: 0;
  background-color: #fff;
  color: #000;
  box-sizing: border-box;
  padding: 1px 0px 5px 0px;
  font-size: 1em;
  text-align: center;
}

.mt150resp {
  margin-top: 70px;
}

@media screen and (max-width: 950px) {
  .mt150resp {
    margin-top: 60px;
  }
}

.programmepay {
  display: flex;
  border: 1px solid #fff;
  width: 200px;
  height: 50px;
  color: white;
  position: relative;
  top: -200px;
  justify-content: center;
  align-items: center;
  transition: 0.5s all ease-in-out;
  z-index: 200;
}

.programmepay:hover {
  background-color: red;
}

.paypro {
  display: flex;
  justify-content: center;
}
