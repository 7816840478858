.form {
  margin-top: 50px;
}

.form h2,
label {
  color: black !important;
}

.formp {
  width: 65%;
  margin: auto;
  color: white;
  text-align: center;
  font-size: 20px;
}

.form-element {
  width: 65%;
  margin: 25px auto;
}

.demande {
  resize: none;
  width: 100%;
  height: 200px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.form-element button {
  border: none;
  outline: none;
  background-color: white;
  color: black;
  border-radius: 8px;
  padding: 10px;
  height: 30px;
  cursor: pointer;
}

.user-box {
  position: relative;
}

.user-box p {
  text-align: left;
  font-size: 18px;
  color: white;
}

.user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: rgb(0, 0, 0);
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid rgb(0, 0, 0);
  outline: none;
  background: transparent;
  font-family: "Roboto", sans-serif;
}

.user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: white;
  pointer-events: none;
  transition: 0.5s;
  font-family: "Roboto", sans-serif;
}

.user-box input:focus ~ label,
.user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: white;
  font-size: 12px;
}

.inputvalide {
  top: -20px !important;
  left: 0 !important;
  color: white !important;
  font-size: 12px !important;
}
