.footer {
  background-color: black;
  width: 100%;
  display: flex;
  align-items: top;
  padding: 1rem 0;
  color: white;
}

.footer section {
  padding: 1rem 2rem;
  width: 100%;
  vertical-align: top;
}

.footer section h3 {
  color: white;
  font-family: "Roboto", sans-serif;
}

.footer p {
  font-family: "Roboto", sans-serif;
}

.footer section a {
  display: flex;
  color: white;
  align-items: center;
  white-space: nowrap;
  margin: 15px 0;
  font-family: "Roboto", sans-serif;
}

.footer section:nth-child(2) a:before,
.footer section:nth-child(3) a:before {
  content: "|";
  font-family: "FontAwesome";
  margin-right: 5px;
  color: white;
}

.sub-footer {
  background-color: #161616;
  text-align: Center;
  color: rgb(255, 255, 255);
  padding: 1rem 0;
}

@media (max-width: 1020px) {
  .footer {
    display: block;
  }

  .footer section {
    padding: 1rem 1rem;
  }

  .footer section:nth-child(2),
  .footer section:nth-child(3) {
    width: 49%;
    display: inline-block;
  }
}

@media (max-width: 500px) {
  .footer {
    display: flex;
    flex-direction: column;
  }

  .footer section:nth-child(2),
  .footer section:nth-child(3) {
    width: 100%;
    display: inline-block;
  }
}
