::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.stats__ {
  color: rgb(0, 0, 0);
  margin-top: 50px;
  text-align: center;
}

.adminForm label {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.adminForm input {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.faq-question {
  width: 95%;
  max-width: 1005px;
  height: 50px;
  background-color: black;
  color: white;
  border-radius: 6px;
  margin: 3px 0;
  display: flex;
  align-items: center;
  margin: 10px auto;
  transition: all 0.5s ease-in-out;
  filter: invert(1);
}

.border-arrow {
  border: solid 3px rgb(255, 255, 255);
  border-radius: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.arrow {
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 2px;
  transition: 0.5s all ease-in-out;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.down {
  transform: rotate(45deg) !important;
  -webkit-transform: rotate(45deg) !important;
  margin: 0 0 3.5px 0;
}

.effect-slidebox {
  flex-direction: column;
  height: unset;
}

.divDisplay {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.arrow-clicked {
  transform: rotate(-45deg);
  -webkit-transform: rotate(45deg);
  margin-right: 1px !important;
  margin-top: -3px;
}

.displayNull {
  display: none;
}

.faq-test {
  display: block !important;
  padding: 0px 20px 20px 20px;
  width: 100%;
}

@media screen and (max-width: 550px) {
  .faq-question {
    font-size: 13px;
  }
}

table {
  width: 100%;
}

.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  background-color: rgb(0, 0, 0);
}

.fl-table p {
  font-family: "Roboto", sans-serif;
}

.fl-table button {
  font-family: "Roboto", sans-serif;
}

.fl-table td,
.fl-table th {
  text-align: center;
  padding: 8px;
  font-family: "Roboto", sans-serif;
}

.fl-table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
}

.fl-table thead th {
  color: #ffffff;
  background: #000000;
}

.fl-table thead th:nth-child(odd) {
  color: #ffffff;
  background: grey;
}

.fl-table tr:nth-child(even) {
  background: #000;
}

@media (max-width: 767px) {
  .fl-table {
    display: block;
    width: 100%;
  }

  .table-wrapper:before {
    content: "Scroll horizontally >";
    display: block;
    text-align: right;
    font-size: 11px;
    color: white;
    padding: 0 0 10px;
  }

  .fl-table thead,
  .fl-table tbody,
  .fl-table thead th {
    display: block;
  }

  .fl-table thead th:last-child {
    border-bottom: none;
  }

  .fl-table thead {
    float: left;
  }

  .fl-table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }

  .fl-table td,
  .fl-table th {
    padding: 20px 0.625em 0.625em 0.625em;
    height: 60px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: auto;
    width: 120px;
    font-size: 13px;
  }

  .fl-table thead th {
    text-align: left;
    border-bottom: 1px solid #000000;
    width: 70px;
  }

  .fl-table tbody tr {
    display: table-cell;
  }

  .fl-table tbody tr:nth-child(odd) {
    background: none;
  }

  .fl-table tr:nth-child(even) {
    background: transparent;
  }

  .fl-table tr td:nth-child(odd) {
    background: #000000;
    border-right: 1px solid #e6e4e4;
  }

  .fl-table tr td:nth-child(even) {
    border-right: 1px solid #ffffff;
  }

  .fl-table tbody td {
    display: block;
    text-align: center;
    background: black;
  }
}

.GetVisioBloc {
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 25px;
  top: 0;
}

.dockOfClose {
  position: absolute;
  top: 0;
  width: 100vw;
  background: #11101d;
  text-align: center;
}

.dockOfClose span {
  color: white;
}

.nodisplay {
  display: none !important;
}

input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 60px;
  height: 30px;
  background: #ccc;
  border-radius: 50px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.4s;
}

input:checked[type="checkbox"] {
  background: #7da6ff;
}

input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  transition: 0.4s;
}

input:checked[type="checkbox"]::after {
  left: 50%;
}

.openDayOpenClosed {
  color: white;
}

.openDayOpenClosed article {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.openDayOpenClosed span {
  margin-right: 10px;
  font-size: 25px;
}

.VacanceMode {
  color: white;
  text-align: center;
  padding: 10px;
}

.coursFont {
  font-family: "Roboto", sans-serif;
}

.liste {
  width: 100%;
  min-height: 50px;
  margin-top: -50px;
  background-color: rgb(0, 0, 0);
  color: white;
  position: fixed;
  padding: 10px 50px;
  text-align: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.nextcours th, .nextcours td {
  font-family: sans-serif;
  text-align: left;
}

.voircours {
  background-color: rgb(0, 0, 0);
  text-align: center !important;
  padding: 5px;
  border-radius: 7px;
  color: white;
}

.scanner-container {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.video-container {
  position: relative;
  width: 256px;
  height: 256px;
  background: #f1f1f1;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
}

.scanner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button-container {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.scan-button {
  flex: 1;
  padding: 4px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.scan-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.scan-button.start {
  background-color: #3b82f6;
}

.scan-button.start:hover:not(:disabled) {
  background-color: #2563eb;
}

.scan-button.stop {
  background-color: #ef4444;
}

.scan-button.stop:hover:not(:disabled) {
  background-color: #dc2626;
}

.result-container {
  font-size: 14px;
}

.result-text {
  color: #0d9488;
}