.martop170 {
  margin-top: 55px;
}

.cardcontainer {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  grid-template-columns: repeat(2, 1fr);
}

.cardcontaining {
  display: flex;
  width: 400px;
  height: 465px;
  margin: 10px;
  background-color: black;
}

.textoncard {
  text-align: center;
  color: white;
  margin-top: 10px;
}

.textoncard h2 {
  font-size: 15px;
}

.textoncard p {
  font-size: 11px;
}

.x400 {
  width: 400px;
  height: 400px;
}

@media screen and (max-width: 960px) {
  .cardcontaining {
    width: 300px;
    height: 365px;
  }

  .x400 {
    width: 300px;
    height: 300px;
  }

  .textoncard {
    margin-top: 1px;
  }
}

@media screen and (max-width: 725px) {
  .cardcontaining {
    width: 200px;
    height: 265px;
    margin-top: 20px;
  }

  .x400 {
    width: 200px;
    height: 200px;
  }

  .textoncard {
    margin-top: 0px;
  }
}

@media screen and (max-width: 530px) {
  .cardcontaining {
    width: 300px;
    height: 365px;
    margin: 30px 0 0 0;
  }

  .x400 {
    width: 300px;
    height: 300px;
  }

  .cardcontainer {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
}

.cardcontaining:nth-last-child(1) {
  margin-bottom: 30px;
}
