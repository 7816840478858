@media screen and (max-width: 606px) {
  .tpsp44 {
    font-size: 15px;
  }
}

.boutiquesection {
  display: flex;
  justify-content: center;
}

.boutiquesection article {
  width: 300px;
  height: 300px;
  margin: 20px 10px;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  align-items: flex-end;
  text-align: center;
}

.boutiquesection article span {
  margin-top: 10px;
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 1140px) {
  .boutiquesection {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
  }
}

@media screen and (max-width: 650px) {
  .boutiquesection {
    display: grid;
    grid-template-columns: repeat(1, 0fr);
  }
}

@media screen and (max-width: 606px) {
  .tpsp44 {
    font-size: 15px;
  }
}
