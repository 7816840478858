@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");

* {
  font-family: "Trickster";
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: "Trickster";
  background-color: bisque;
}

@font-face {
  font-family: "Trickster";
  src: url(../fonts/Mortend.otf) format("opentype");
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border: 1px red;
  border-radius: 50px;
  height: 3px;
  width: 10px;
}

ul {
  list-style: none;
}

#onpage {
  color: #e91e63;
}

.copy {
  width: 100%;
  position: absolute;
  bottom: 15px;
}

.copy p {
  text-align: center;
  color: #fff;
  transition: all 0.5s ease;
  font-size: 12px;
  width: 100%;
}

.copy p:hover {
  padding-left: 8px;
}

.mentions p,
.mentions h2,
.mentions b,
.mentions a,
.mentions strong,
.mentions h3 {
  font-family: "Roboto", sans-serif !important;
}

.mentions {
  color: rgb(0, 0, 0);
  font-size: 15px;
  margin: 30px 40px 20px 40px;
}

.mentions a {
  text-decoration: none;
  color: rgb(255, 0, 0);
}

.block-legals p {
  margin-top: 8px;
}

.def p {
  margin-top: 0 !important;
}

.block-legals {
  margin-top: 15px;
}

nav #active {
  color: #931b33;
}

@media screen {
  .phone-parallax {
    background-attachment: initial;
  }
}

.parallax {
  background-image: url("/public/img/a/lsqcentrale.webp");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-text {
  color: black;
  font-size: 45px;
}

.bgw {
  background-color: white;
  color: black;
}

.nopadd {
  padding: 0;
}

.f13 {
  font-size: 13px;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.movehover:hover {
  margin-top: 5px;
  transition: all 0.4s ease;
}

.parralax-textw {
  text-align: center;
  align-items: center;
  display: grid;
  height: 500px;
  margin-top: 30px;
}

@media screen and (min-width: 576px) {
  .bottom--576 {
    bottom: 0;
  }
}

.blog--coverimg:nth-child(1):before {
  background-image: url("/public/img/a/elaslsq.webp");
  height: 80%;
}

@media (max-width: 600px) {
  .mentions {
    font-size: 13px;
  }

  .form {
    width: 100%;
  }

  .formp {
    font-size: 14px;
    width: 80%;
  }

  .form-element {
    width: 80%;
  }

  .unsetWidth {
    width: 80% !important;
  }
}

.whatsapp-contact {
  position: fixed;
  bottom: 50%;
  right: 2px;
  z-index: 999;
  display: grid;
}

@media (max-width: 768px) {
  .whatsapp-contact img {
    width: 45px;
    height: 45px;
  }
}

@media screen and (min-width: 768px) {
  .whatsapp-contact {
    display: none;
  }
}

.philobloctxt {
  font-size: 50px;
  margin-left: 20px;
}

.whatsapp-button {
  margin: 15px auto;
  height: 60px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in;
}

.app-box {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
}

.whatsapp-button:nth-last-child(4) {
  background-color: red;
}

.mtoff {
  margin-top: 50px;
  margin-left: 10px;
}

.off2button {
  width: 150px;
  position: relative;
  bottom: -6.5px;
  right: 18px;
  background-color: white;
  color: black;
}

.off2button:hover {
  width: 170px;
  background-color: black;
  color: white;
}

.g-recaptcha {
  margin: -20px 0 10px 0;
}

#linenone {
  border-bottom: initial;
  line-height: 0;
}

#linenone2 {
  border-bottom: initial;
  line-height: 0;
}

@media (max-width: 631px) {
  .off2button {
    bottom: -6.5px;
    right: 18px;
  }
}

.blog-pc {
  margin-top: 110px;
  height: 400px;
}

.nombmt50 {
  margin-bottom: 0;
  margin-top: 50px;
}

.nombmt10 {
  margin-bottom: 0;
  margin-top: 10px;
}

.gingerlab {
  transition: all 0.5s ease-in;
}

.gingerlab:hover {
  background-color: black;
  color: white;
}

.buttoncont {
  display: none;
}

@media (min-width: 961px) {
  .whatsapp-button {
    display: none;
  }

  .buttoncont {
    display: block;
    width: 65%;
    margin: 20px auto 40px auto;
    display: grid;
    justify-content: center;
  }

  .buttoncont p {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 10px;
  }
}

.buttercontmail {
  display: flex;
}

.buttercontphone {
  display: flex;
  margin-bottom: 10px;
}

.titlebox9 {
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 30px;
  margin-top: 200px;
}

.user-box9 {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  text-align: center;
  margin-top: 50px;
  min-height: 100px;
  justify-content: center;
}

.user-box9 a {
  color: rgb(143, 129, 129);
}

.loggednav {
  position: fixed;
  height: 100%;
  width: 20%;
  background: rgb(255, 255, 255);
  left: 0;
  top: 0;
}

.navldn {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.navldn a {
  margin-right: 10px;
  color: #000;
}

.success {
  color: green;
}

.notsuccess {
  color: red;
}

.respx {
  width: 30px;
}

.reservation {
  height: 700px !important;
  width: 100% !important;
}

#mgsqv {
  width: 150px;
  height: 50px;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@media screen and (max-width: 1000px) {
  .navldn {
    font-size: 15px;
  }
}

@media screen and (max-width: 850px) {
  .loggednav {
    display: none;
  }

  .reservation {
    width: 100% !important;
    left: 0;
    margin-top: 10px !important;
  }

  .subnav {
    display: block;
    background-color: white;
    width: 100%;
    height: 160px;
    bottom: 0;
  }

  .p-backto {
    background-color: gray;
    text-align: center;
    color: white;
  }

  .avldn {
    padding: 10px 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subock {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .subobloc {
    width: 24%;
    display: grid;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .subobloc img {
    margin-left: 20px;
  }

  .splash {
    margin-left: 35px !important;
  }

  .dmnn {
    display: block;
  }

  .subobloc p {
    color: black;
    text-align: center;
  }

  .subobloc p:focus {
    color: black;
  }
}

@media (max-width: 510px) {
  .splash {
    margin-left: 7.5vw !important;
  }
}

@media (min-width: 850px) {
  .subnav {
    display: none;
  }

  .dmnn {
    display: none;
  }

  .accountmanager {
    width: 80%;
    position: relative;
    left: 20%;
  }
}

@media (max-width: 400px) {
  .subobloc {
    font-size: 12px;
  }

  .subobloc img {
    width: 30px;
    height: 30px;
  }
}

.nohe {
  height: unset;
}

.mt80 {
  margin-top: 80px !important;
  margin-bottom: 20px;
}

.calender-book {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.calender-book h2 {
  margin-top: 10px;
  color: white;
}

.calender-book article {
  margin-top: 10px;
}

.calender-book article a {
  padding: 4px 15px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-decoration: none;
  border-radius: 5px;
}

@media only screen and (max-width: 465px) {
  .calender-book a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 400px) {
  .calender-book a {
    padding: 3px 1px 3px 1px;
  }
}

@media screen and (max-width: 400px) {
  .calender-book article a {
    font-size: 12px;
    padding: 4px;
  }
}

.booklook {
  color: white;
  text-align: center;
}

.c-timety {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.timety {
  background: black;
  border: none;
  outline: none;
  width: 100px;
  height: 50px;
  margin: 5px;
  color: white;
  border-radius: 3px;
  transition: all 0.5s;
}

.success5 {
  background: green;
  color: black;
  margin-top: 150px;
  text-align: center;
}

.timestop {
  background: rgb(255, 0, 0);
  border: none;
  outline: none;
  width: 100px;
  height: 50px;
  margin: 5px;
  color: rgb(0, 0, 0);
  border-radius: 3px;
}

.nonono {
  margin-top: 150px;
  background: red;
  text-align: center;
  font-size: 25px;
}

.ermsg {
  background: red;
  width: 100%;
  font-size: 30px;
  color: white;
  text-align: center;
}

.labelnomove {
  top: -20px !important;
  left: 0 !important;
  color: white !important;
  font-size: 12px !important;
}

.multiselect {
  border-bottom: 1px solid #000;
  height: 40px;
  border: none;
  width: 100%;
  margin-bottom: 20px;
  background-color: black;
  color: white;
}

.success9 {
  width: 80%;
  height: 50%;
  background-color: white;
}

.containerfinilo {
  width: 80%;
  height: 500px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containerfinilo h1 {
  color: black;
  margin-top: 10px;
  text-align: center;
}

.buttoncf {
  width: 100%;
  text-align: center;
}

.buttoncf button {
  border: none;
  background-color: black;
  color: white;
  min-width: 100px;
  height: 30px;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.timety:focus {
  background-color: black;
  color: white;
}

.reservationblocpcontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  align-items: center;
  flex-direction: column;
}

.reservationbloc {
  width: 80%;
  max-width: 600px;
  height: 115px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 7.5px 0;
  border-radius: 10px;
}

.seancerbloc:last-of-type {
  margin-bottom: 75px;
}

.seancerbloc {
  padding: 10px 0 0 15px;
  width: 100%;
}

.seancerbloc img {
  width: 100px;
  margin-top: 5px;
}

.seancerpay {
  display: flex;
  align-items: center;
  margin: 0 10px 10px 0;
  justify-content: flex-end;
  height: 100%;
}

.btnpay {
  width: 75px;
  height: 25px;
  border: none;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 3px;
  margin: 0 0 0 5px;
  cursor: pointer;
}

.etatpay {
  width: 100px;
  height: 25px;
  border: none;
  background-color: red;
  color: white;
  border-radius: 3px;
}

.seancerbloc h2 {
  font-size: 20px;
}

.seancerbloc p {
  font-size: 15px;
}

@media (max-width: 500px) {
  .seancerbloc h2 {
    font-size: 16px;
  }
}

.etatpaygreen {
  background-color: green !important;
}

.modalcheck {
  width: 50vw;
  max-width: 500px;
  height: 200px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
}

.buttonpay {
  width: 70%;
  height: 50px;
  border: none;
  background-color: #7c7879;
  font-size: 22px;
  color: white;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
  cursor: pointer;
}

.buttonmodal {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  flex-direction: column;
  align-items: center;
}

.buttonpay:hover {
  background-color: #5c5658;
  color: white;
}

@media screen and (max-width: 750px) {
  .modalcheck {
    width: 90vw;
  }
}

.hidden {
  display: none;
}

.footerphoto {
  margin-top: 2px;
  margin-left: -4px;
  padding-right: 5px;
}

.demandelabel {
  position: unset !important;
}

.fichesec {
  background-color: white;
  display: flex;
  padding: 100px 25px 25px 25px;
}

.fichesec h2,
.fichesec p,
.fichesec label,
.fichesec span {
  font-family: "Roboto", sans-serif;
}

.ficherad p {
  font-family: "Roboto", sans-serif;
}

.fichearticle {
  padding-left: 20px;
}

.fichearticle h1 {
  font-weight: 400;
}

.fichearticle p {
  margin-top: 5px;
}

.radio {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.radio article label {
  padding-left: 10px;
}

.ficherad {
  background-color: white;
  padding: 20px 25px 20px 25px;
}

.ficherad h2 {
  font-size: 15px;
}

.ficherad p {
  font-size: 15px;
  margin-top: 5px;
}

.paybuttonfiche {
  background-color: #000;
  color: white;
  width: 100px;
  height: 30px;
  line-height: 1.9em;
  text-align: center;
  margin-top: 10px;
  border: none;
}

@media (max-width: 660px) {
  .fichesec {
    flex-direction: column;
  }

  .imgfiche {
    margin: auto;
    margin-bottom: 10px;
  }

  .ficherad p {
    font-size: 12px;
  }

  .fichearticle {
    font-size: 12px;
  }

  .paybuttonfiche {
    font-size: 15px;
  }
}

.paypre {
  color: white;
  cursor: pointer;
}

.formcheck {
  width: 700px;
  height: 500px;
  background-color: white;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formcheck h2 {
  text-align: center;
}

@media (max-width: 710px) and (min-width: 500px) {
  .formcheck {
    width: 500px;
  }
}

@media (max-width: 500px) and (min-width: 310px) {
  .formcheck {
    width: 310px;
  }

  .formcheck h2 {
    font-size: 15px;
  }

  .textinput88 {
    width: 70% !important;
  }

  #buttonText {
    font-size: 15px;
  }

  .buttonpay {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.textinput88 {
  width: 50%;
  margin-top: 20px;
  height: 45px;
}

.btndw {
  padding: 5px;
  height: 25px;
  border: none;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 3px;
  margin: 0 0 0 5px;
  cursor: pointer;
}

@media (max-width: 501px) {
  #buttonachat {
    font-size: 12px;
  }
}

.buypass {
  align-items: flex-end;
  margin-bottom: 5px;
}

.sietof {
  margin-bottom: 40px;
}

.iframegoogle {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.afterend {
  margin-top: 30px;
}

.border-buttoned {
  border: 2px solid rgb(0, 0, 0);
  color: black;
  padding: 5px;
  font-size: 20px;
  transition: all 0.5s ease-in-out;
}

.border-buttoned:hover {
  background-color: transparent;
  border: 2px solid rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.formh1co {
  text-align: center;
}

.twisty {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.twisty a {
  border: 1px solid #000;
  color: rgb(0, 0, 0);
  height: 40px;
  text-align: center;
  display: grid;
  align-items: center;
  margin: 10px 0;
  transition: 0.5s all ease-in-out;
}

.twisty a:hover {
  background-color: black;
  color: white;
}

@media (max-width: 500px) {
  .twisty a {
    width: 80%;
    margin: 10px auto;
  }
}

.phonies {
  height: 35px !important;
}

.c-recaptcha {
  display: flex;
  justify-content: center;
  margin: 0 !important;
}

.erreurbox {
  color: white;
  margin: 10px 0;
}

.notif {
  width: 100%;
  height: 50px;
  background-color: rgb(255, 255, 255);
  color: black;
  text-align: center;
  display: grid;
  align-items: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.header-log {
  display: block;
  height: unset;
  padding: 0;
  color: black;
}

.alerte-box {
  color: white;
  padding: 10px 0;
}

.react-calendar {
  max-width: 1000px;
  background: rgb(248, 240, 240);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  display: flex;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #000000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: grey;
  color: black;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation__label__labelText {
  text-transform: capitalize;
  font-size: 20px;
}

.react-calendar__navigation__arrow {
  font-size: 25px;
}

.react-calendar__navigation__label {
  background-color: unset !important;
  color: black;
}

.ifppa {
  height: 1%;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: rgb(248, 240, 240);
  border-radius: 25px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: 0.5s all ease-in-out;
}

.ifppa .react-calendar {
  margin: 15px auto;
}

.openModal80 {
  display: block !important;
  height: 80%;
}

.timesmodal {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  padding: 25px 25px 0 0;
}

.timesmodal span {
  font-size: 40px;
  cursor: pointer;
}

.slot {
  text-align: center;
  background: rgb(248, 240, 240);
}

.changeColooor label {
  color: black !important;
}

.changeColooor input {
  color: black;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.blackbutton {
  background-color: black !important;
  color: white !important;
}

.blocOfAlert {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.expiredRes {
  background-color: grey;
  color: white;
}

.movie--isloading {
  height: 252px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 15px;
}

.disabledButtonWaiting {
  background-image: -webkit-linear-gradient(
    left,
    #ececec 0px,
    #f4f4f4 40px,
    #ececec 80px
  );
  background-image: -o-linear-gradient(
    left,
    #ececec 0px,
    #f4f4f4 40px,
    #ececec 80px
  );
  background-image: linear-gradient(
    90deg,
    #ececec 0px,
    #f4f4f4 40px,
    #ececec 80px
  );
  background-size: 250px;
  font-size: 0px;
  -webkit-animation: shine-loading-image 2s infinite ease-out;
  animation: shine-loading-image 2s infinite ease-out;
}

@-webkit-keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }

  40%,
  100% {
    background-position: 208px;
  }
}

@keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }

  40%,
  100% {
    background-position: 208px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderPay {
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 25px;
  top: 0;
}

.nodisplay {
  display: none !important;
}

.text-align {
  display: flex;
  justify-content: center;
}

.barred-text {
  text-decoration: line-through;
}

.boutonchoix {
  width: unset;
  margin: 0 3px;
  padding: 2px;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0) !important;
}

@media screen and (max-width: 450px) {
  .etatpay {
    font-size: 11px;
    height: 32px;
  }

  .btnpay {
    height: 32px;
  }
}

.GetVisioBloc {
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 25px;
  top: 0;
}

.dockOfClose {
  position: absolute;
  top: 0;
  width: 100vw;
  background: #11101d;
  text-align: center;
}

.dockOfClose span {
  color: white;
}

.surwrapper {
  display: none;
}

.addSlay {
  display: block;
}

.containerOfDashBoard {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 60px;
  background: #11101d;
  padding: 2px 6px;
  transition: all 0.5s ease;
  z-index: 999;
}

.sidebar.active {
  width: 240px;
}

.sidebar .logo_content .logo {
  color: #fff;
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  font-size: 20px;
  opacity: 0;
  pointer-events: none;
}

.sidebar.active .logo_content .logo {
  opacity: 1;
  pointer-events: none;
}

.logo_content.logo i {
  font-size: 28px;
  margin-right: 5px;
}

.logo_content .logo .logo_name {
  font-size: 20px;
  font-weight: 400;
}

.sidebar #btn {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 6px;
  font-size: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  transform: translateX(-50%);
}

.sidebar.active #btn {
  left: 90%;
}

.sidebar ul {
  margin-top: 20px;
}

.sidebar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  margin-top: 0 5px;
  list-style: none;
  line-height: 50px;
}

.sidebar ul li .tooltip {
  position: absolute;
  left: 130px;
  top: 0;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  height: 35px;
  padding: 0 5px;
  background: #fff;
  line-height: 35px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}

.sidebar.active ul li .tooltip {
  display: none;
}

.sidebar ul li:hover .tooltip {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.sidebar ul li input {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 12px;
  outline: none;
  border: none;
  background: #1d1b31;
  padding-left: 50px;
  font-size: 18px;
  color: #fff;
}

.sidebar ul li .bx-search {
  position: absolute;
  z-index: 99;
  color: #fff;
  font-size: 22px;
  transition: all 0.5s ease;
}

.sidebar ul li .bx-search:hover {
  background: #fff;
  color: #11101d;
}

.sidebar.active ul li .bx-search:hover {
  background: #1d1b31;
  color: #fff;
}

.sidebar ul li a {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}

.sidebar ul li a:hover {
  background: #fff;
  color: #11101d;
}

.sidebar ul li i {
  height: 50px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
}

.sidebar .link_names {
  opacity: 0;
  pointer-events: none;
}

.sidebar.active .link_names {
  opacity: 1;
  pointer-events: auto;
}

.sidebar .profile_content {
  position: absolute;
  color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
}

.sidebar .profile_content .profile {
  position: relative;
  padding: 10px 6px;
  height: 60px;
  background: #1d1b31;
}

.profile_content .profile .profile_details {
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
}

.sidebar.active .profile .profile_details {
  opacity: 1;
  pointer-events: auto;
}

.profile .profile_details img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 12px;
}

.profile .profile_details .name_job {
  margin-left: 10px;
}

.profile .profile_details .name {
  font-size: 15px;
  font-weight: 400;
}

.profile .profile_details .job {
  font-size: 12px;
}

.profile #log_out {
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  min-width: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 12px;
  text-align: center;
}

.profile #log_out:hover {
  background: #fff;
  color: #11101d;
}

.sidebar.active .profile #log_out {
  left: 88%;
}

.home_content {
  position: absolute;
  left: 60px;
  width: calc(100vw - 60px);
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
}

.sidebar.active ~ .home_content {
  left: 240px;
  width: calc(100% - 240px);
}

.home_content .text {
  font-size: 25px;
  font-weight: 500;
  color: #1d1b31;
  margin: 12px;
}

.exceptionForAchats {
  min-height: 100px;
  height: unset;
}

.dashmargin {
  margin-top: 15px;
}

.dashboardSpecial {
  justify-content: center;
  margin-top: 0;
}

.containerOfAlertBox {
  display: flex;
  justify-content: center;
}

.unsetWidth {
  width: unset;
}

.arrowMoving {
  height: 24px;
  display: flex;
}

.arrowMoving:before {
  content: "";
  background: currentColor;
  width: 15px;
  clip-path: polygon(
    0 10px,
    calc(100% - 15px) 10px,
    calc(100% - 15px) 0,
    100% 50%,
    calc(100% - 15px) 100%,
    calc(100% - 15px) calc(100% - 10px),
    0 calc(100% - 10px)
  );
  animation: arrow 1.5s infinite linear;
}

@keyframes arrow {
  90%,
  100% {
    flex-grow: 1;
  }
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 75px;
  background-color: #333;
  color: #fff;
  border-top-left-radius: 15px 15px;
  border-top-right-radius: 15px 15px;
  font-size: min(max(16px), 3vw);
}

.bottom-nav li {
  display: flex;
  flex-direction: column;
}

.linkOfBottomNav {
  flex: 1;
  text-align: center;
  text-decoration: none;
  color: #fff;
}

.redox {
  left: 0;
  width: 100%;
}

.logoutbtn {
  width: 100px;
  height: 30px;
  background-color: white;
  border: none;
  margin-bottom: 20px;
}

.mb100 {
  margin-bottom: 100px;
}

.chat {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat__main {
  width: 100vw;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 50px);
}

.message__container {
  margin-top: 10px;
  overflow-y: auto;
  height: calc(100vh - 115px);
}

.message__chats {
  height: auto;
  min-height: 15px;
  background-color: green;
  border-radius: 4px;
  width: auto;
  height: auto;
  color: white;
  display: flex;
  margin: 10px 0;
  padding: 2px 0;
}

.message__chats .sender__name {
  margin-right: 5px;
}

.chat__mainHeader {
  height: auto;
  text-align: center;
  justify-content: center;
  width: 90%;
}

.chat__mainHeader h3 {
  color: black;
  font-size: medium;
}

.chat__footer {
  position: absolute;
  bottom: 0;
}

.chat__footer form {
  margin-top: 0 !important;
}

.chat__footer form .user-box {
  width: 100vw;
  display: flex;
  align-items: center;
}

.chat__footer form .user-box input {
  margin-bottom: 0;
}

.chat__footer form .user-box .sendBtn {
  height: auto;
}

.button {
  border: none;
  width: 100px;
  height: 30px;
  background: dodgerblue;
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.67, 0.17, 0.4, 0.83);
  align-items: center;
  display: flex;
  justify-content: center;
}

.button svg {
  transform: rotate(180deg);
  transition: all 0.5s;
}

.button__circle {
  width: 30px;
  height: 27px;
  background: mediumseagreen;
  border-radius: 100px;
  transform: rotate(-180deg);
}

.button:hover {
  cursor: pointer;
}

.tick {
  color: white;
  font-size: min(max(15px), 4vw);
  transition: all 0.9s;
}

.noLefties {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: grey;
}

.message__sender {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message__sender h6 {
  padding-left: 10px;
}

.message__recipient {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message__recipient h6 {
  padding-left: 10px;
}

#visioHanglerFChat {
  align-items: center;
}

.bronzitoBloc {
  border: 5px solid #967444;
  border-radius: 10px;
}

.argentitoBloc {
  border: 5px solid #c0c0c0;
  border-radius: 10px;
}

.golditoBloc {
  border: 5px solid #ffd700;
  border-radius: 10px;
}

.platinitoBloc {
  border: 5px solid #e5e4e2;
  border-radius: 10px;
}

.message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: 0.5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
  transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
  transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
  transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
  transform: perspective(800px) rotateY(-179.9deg);
}

.message:first-child {
  margin: 16px 0 8px;
}

.message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message.received .metadata {
  padding: 0 0 0 16px;
}

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

@media screen and (min-width: 650px) {
  .chat__main {
    width: 95vw;
  }

  .chat__footer form .user-box {
    width: 95vw;
  }
}

.nametime-msg {
  font-weight: bold;
}

.RemoveXScroll::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px !important;
}

.admin {
  background-color: black !important;
  color: white;
}

.admin .metadata .time {
  color: grey;
}

.spacebecauseofNumber {
  margin-top: 20px;
}

.baniereapp {
  background-color: rgb(255, 255, 255);
  width: 100%;
  display: flex;
  align-items: center;
}

.baniereapp img {
  width: 48px;
  height: 48px;
  margin-left: 5px;
}

.baniereapp p {
  font-size: 3vw;
  font-weight: bold;
  color: black;
  margin: 0 6px 0 3px;
}

.baniereapp button {
  border: none;
  border-radius: 2px;
  background-color: black;
  color: white;
  padding: 3px;
}

.inscripentre {
  color: white;
  text-align: center;
}

.namespace .reservationbloc {
  height: auto;
  min-height: 115px;
}

.boxdefragile {
  border-bottom: 1px solid black !important;
}

.boxb-box {
  position: relative;
  display: flex;
  flex-direction: column;
}

.boxb-box label {
  color: #000;
}

.boxb-box input {
  color: #000;
  border: 0;
}

.hide {
  display: none !important;
}

.restrictionout {
  height: auto;
  min-height: 115px;
  padding: 5px;
}

@media screen and (max-width: 400px) {
  #blocsupper .reservationbloc {
    width: 100%;
  }
}

.validah1 {
  color: rgb(0, 0, 0);
  text-align: center;
}

.marginiezedbutton {
  margin: 5px;
}

.recentralize {
  margin-top: 50px;
}

.alertMsdg {
  height: auto;
  padding: 5px;
}

.blackcolored {
  color: black;
  text-decoration-line: underline;
}

.invertcolor {
  filter: invert(1);
}

.agoodthingforsure {
  border: none;
  padding: 10px;
  background-color: #000;
  color: white;
  border-radius: 8px;
  min-width: 90px;
  cursor: pointer;
}

.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 45%);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  top: 0;
}

.modalContainer {
  width: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin: 10px 0;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footering {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footering button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

.hiuxxw {
  background-color: black;
}

.radioHandling {
  display: flex;
  justify-content: space-around;
  color: white;
  padding: 5px 0 15px 0;
  align-items: center;
}

.checkbox-normal {
  width: 30px !important;
  height: 15px !important;
}

.checkbox-normal::after {
  width: 15px !important;
  height: 15px !important;
}

.cypherred {
  content: "Cours avec accès par code";
  text-align: center;
  background-color: rgb(0, 0, 0);
  color: white;
  width: 100%;
  height: 50px;
  padding: 5px 0;
}

.cypherinvert {
  filter: invert();
  margin: 20px 0;
}

.skeleton-loading {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.swal2-title {
  font-family: sans-serif;
}

.swal2-html-container {
  font-family: sans-serif;
}

.cc-container {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  justify-items: center;
}

.card-chat {
  min-height: 200px;
  margin: 0 10px;
  background-color: rgb(46, 46, 46);
  width: 80%;
  border-radius: 8px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  margin: 10px 0;
}

.card-chat h2,
.card-chat p {
  font-family: "Roboto", sans-serif !important;
}

@media screen and (max-width: 414px) {
  .cc-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.chat-frame {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 200px;
}

.chat-frame article {
  width: 80%;
  max-width: 600px;
  padding: 10px;
  background-color: #000;
  margin: 10px 0;
  border-radius: 8px;
  color: white;
}

.chat-frame h2,
.chat-frame p {
  font-family: "Roboto", sans-serif !important;
}

.input-chat {
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-chat input {
  width: 30%;
  height: 50px;
  background-color: #000;
  color: white;
  border: 0;
  border-radius: 8px;
  font-family: "Roboto", sans-serif !important;
}

.input-chat button {
  padding: 17px;
  margin: 0 15px;
  background-color: #000;
  color: white;
  border: 0;
  border-radius: 8px;
  font-family: "Roboto", sans-serif !important;
}
