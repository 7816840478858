.h1-inscription {
  text-align: center;
  margin-top: 50px;
}

.inscription-seance img {
  width: 35px;
  height: 35px;
}

.inscription .qualities {
  margin: 0;
}

.inscription .presentation-coach-txt {
  margin: 0 10px;
}

.inscription .presentation-coach {
  max-width: 800px;
}

.inscription h2 {
  font-size: 20px;
  text-decoration: none;
  font-family: sans-serif;
  margin: 0 10px;
}

.inscription .qualities article {
  margin: 3px 5px;
  padding: 10px;
  height: unset;
}

.inscription .qualities article h3 {
  padding: 0 5px;
}

.inscription img {
  height: unset;
}

.logobook {
  border: 2px solid;
}

.inscription {
  max-width: 900px;
}

.button-inscription {
  border: 0;
  width: auto;
  background-color: black;
  padding: 10px;
  color: white;
  border-radius: 7px;
  font-family: sans-serif;
  margin-left: 5px;
}

.skeleton-bloc {
  width: 150px;
  height: 35px !important;
}

.container-inscription {
  width: 80%;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .container-inscription {
    width: 100%;
    font-size: 20px;
  }
  .h1-inscription {
    font-size: 20px;
  }
  .presentation-coach-img {
    width: 100%;
    margin-left: 30px;
  }
}
