.buttonStart {
  height: 60px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
  width: 80vw;
  max-width: 300px;
  border-radius: 2px;
}

.buttonStart span {
  color: black;
}

.text-boxPWA {
  align-items: center;
  width: 100%;
}

.box-videoPWA {
  height: 100vh;
}

.pwaBoxvideo {
  height: 100vh !important;
  max-height: 100vh !important;
}

@media screen and (min-width: 701px) and (max-width: 1100px) {
  .text-boxPWA {
    font-size: unset !important;
  }
}

@media screen and (max-width: 700px) {
  .text-boxPWA {
    align-items: center;
  }
}

.noThanMargin {
  margin-top: 150px !important;
}
